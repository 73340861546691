import {AsyncThunkAction, createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {Signer} from 'ethers'
import i18next from 'i18next'
import {RootState} from './store'
import Web3 from 'web3'
import {CHAINS} from '../utils/constants'
import {
    ModalConfirmationType,
    ModalSendTransactionType,
    ModalErrorType,
    SliceResponse,
    ModalEditTicketPricesType,
    ModalSendTokenType,
    InputData,
    initInputData,
    ModalMintSbtType,
    IWalletQueue,
    ICheckedTicket, ModalAddEventToShowcaseType, ModalCreateShowcaseV2Type, ModalEditStringType, ModalVideoType
} from './types'
import {setOrganizers, setSelectedOrganizerId} from './organizersSlice'
import {setEventsByOrganizer, setSelectedEventId} from './eventsSlice'
import {setTicketLevelId, setTicketLevels} from './ticketsSlice'
import {
    getFromNetworkStorage,
    getFromStorage,
    getFromWalletStorage, setToNetworkStorage,
    setToStorage,
} from './storage'
import {requestAuth, setJwt, setUser} from './authSlice'
import {setGiftsCollections, setSelectedCollection as setSelectedGiftCollection} from './giftsSlice'
import {setCollections as setMintCollections} from './mintSlice'
import {setCollections as setSbtCollections} from './sbtSlice'
import {setNames} from './ensSlice'
import {setOnChainEvents} from './onChainEventsSlice'
import {sleep} from '../utils/functions'

interface AppState {
    currentNetwork: string | null
    dateFilter: Date | null
    dateFilterAsc: boolean
    externalUrl: string
    imageUrl: InputData<string | null>
    initialized: boolean
    initializeError: string
    loadedImage: File | null
    mainPageClass: MainPageClasses | null
    modalAddEventToShowcase: ModalAddEventToShowcaseType | null
    modalAddProperties: boolean
    modalAddShowcaseToEvent: boolean
    modalAddTicketsToLazyShowcase: ICheckedTicket | null
    modalAddTicketsToShowcase: boolean
    modalAddTicketsToShowcaseV2: boolean
    modalConfirmation: ModalConfirmationType | null
    modalCreateDisplay: boolean
    modalCreateEvent: boolean
    modalCreateGiftsCollection: boolean
    modalCreateLazyDisplay: boolean
    modalCreateMintCollection: boolean
    modalCreateOnChainEvent: boolean
    modalCreateSbtCollection: boolean
    modalCreateShowcaseV2: ModalCreateShowcaseV2Type | null
    modalCreateTicketLevels: boolean
    modalEditDisplay: string | null
    modalEditEvent: boolean
    modalEditLazyDisplay: string | null
    modalEditShowcase: string | null
    modalEditString: ModalEditStringType | null
    modalEditTicketPrices: ModalEditTicketPricesType | null
    modalError: ModalErrorType | null
    modalGenerateAIGift: boolean
    modalMintGifts: boolean
    modalMintSbt: ModalMintSbtType | null
    modalMintTickets: boolean
    modalSendToken: ModalSendTokenType | null
    modalSendTransactions: ModalSendTransactionType | null
    modalVideo: ModalVideoType | null
    redirectPath: string | null
    requestQueue: AsyncThunkAction<any, any, any>[]
    selectedMintCollection: string | null
    selectedSbtCollection: string | null
    signer: Signer | null
    walletAddress: string | null
    walletQueue: IWalletQueue[]
    web3: Web3 | null
}

type MainPageClasses = 'page-home' | 'page-404'

interface LoadingExternalUrlType {
    error?: string
    file?: File
}
const initialState: AppState = {
    currentNetwork: null,
    dateFilter: null,
    dateFilterAsc: false,
    externalUrl: '',
    imageUrl: initInputData(null),
    initialized: false,
    initializeError: '',
    loadedImage: null,
    mainPageClass: null,
    modalAddEventToShowcase: null,
    modalAddProperties: false,
    modalAddShowcaseToEvent: false,
    modalAddTicketsToLazyShowcase: null,
    modalAddTicketsToShowcase: false,
    modalAddTicketsToShowcaseV2: false,
    modalConfirmation: null,
    modalCreateDisplay: false,
    modalCreateEvent: false,
    modalCreateGiftsCollection: false,
    modalCreateLazyDisplay: false,
    modalCreateMintCollection: false,
    modalCreateOnChainEvent: false,
    modalCreateSbtCollection: false,
    modalCreateShowcaseV2: null,
    modalCreateTicketLevels: false,
    modalEditDisplay: null,
    modalEditEvent: false,
    modalEditLazyDisplay: null,
    modalEditShowcase: null,
    modalEditString: null,
    modalEditTicketPrices: null,
    modalError: null,
    modalGenerateAIGift: false,
    modalMintGifts: false,
    modalMintSbt: null,
    modalMintTickets: false,
    modalSendToken: null,
    modalSendTransactions: null,
    modalVideo: null,
    redirectPath: null,
    requestQueue: [],
    selectedMintCollection: null,
    selectedSbtCollection: null,
    signer: null,
    walletAddress: null,
    walletQueue: [],
    web3: null,
}

export const checkResponse = createAsyncThunk(
    'app/checkResponse',
    async (response: SliceResponse, {dispatch}): Promise<void> => {
        response.beforeCheckCallback?.()
        switch (response.status) {
            case 200:
                response.successCallback?.()
                break
            case 401:
                //todo: show auth error
                break
            default:
                response.error = {
                    title: i18next.t('error.error'),
                    text: response.error?.text || i18next.t('error.someError'),
                }
        }
        let data = response.data
        if (response.error) {
            if (response.status !== 401) {
                dispatch(setModalError({...response.error, buttons: ['close']}))
            }
            data = response.defaultData || null
        }
        response.setData?.(data)
        response.afterCheckCallback?.()
    }
)

export const checkWallet = createAsyncThunk(
    'app/checkWallet',
    async (params: IWalletQueue, {dispatch, getState}): Promise<void> => {
        const {request, network} = params
        const state = getState() as RootState
        const {currentNetwork, walletAddress} = state.app

        if (currentNetwork && walletAddress && (!network || network === currentNetwork)) {
            dispatch(request)
        } else {
            dispatch(addToWalletQueue(params))
        }
    }
)

export const initialize = createAsyncThunk(
    'app/initialize',
    async (_, {dispatch, getState}): Promise<boolean> => {
        const connectedWallet = getFromStorage('connectedWallet')
        if (!connectedWallet) {
            setToStorage('walletAddress', null)
        }
        dispatch(setJwt(getFromWalletStorage('jwt')))
        dispatch(setSelectedOrganizerId(Number(getFromWalletStorage('organizerId'))))
        dispatch(setSelectedEventId(Number(getFromWalletStorage('eventId'))))
        const time = Date.now()
        while (time + 10000 > Date.now()) {
            const state = getState() as RootState
            const {walletAddress} = state.app
            if (!connectedWallet || walletAddress) {
                return true
            }
            await sleep(1000)
        }
        if (connectedWallet) {
            setToStorage('connectedWallet', null)
            return true
        }
        return false
    }
)

export const initializeNetwork = createAsyncThunk(
    'app/initializeNetwork',
    async (_, {dispatch, getState}): Promise<void> => {
        const state = getState() as RootState
        const {currentNetwork} = state.app

        dispatch(setSelectedMintCollection(getFromNetworkStorage('selectedMintCollection', currentNetwork)))
        dispatch(setSelectedSbtCollection(getFromNetworkStorage('selectedSbtCollection', currentNetwork)))
        dispatch(setSelectedGiftCollection(getFromNetworkStorage('selectedGiftCollection', currentNetwork)))
    }
)

export const loadExternalUrl = createAsyncThunk(
    'app/loadExternalUrl',
    async (url: string): Promise<LoadingExternalUrlType | null> => {
        if (url === '') {
            return null
        }
        const res = await fetch(url, {mode: 'no-cors'})
        if (res.status === 200 || res.status === 0) {
            const contentType = res.headers.get('Content-Type') || undefined
            const data = await res.arrayBuffer()
            const blob = new Blob([data])
            const file = new File([blob], 'filename', {type: contentType})
            return {file}
        } else {
            console.log(`Loading error with status ${res.status}`)
            return {error: res.statusText}
        }
    }
)

export const networkChanged = createAsyncThunk(
    'app/networkChanged',
    async (_, {dispatch, getState}): Promise<void> => {
        const state = getState() as RootState
        const {currentNetwork, walletAddress} = state.app

        dispatch(setNames({}))
        if (walletAddress) {
            dispatch(setMintCollections(null))
            dispatch(setSbtCollections(null))
            dispatch(setGiftsCollections(null))
            dispatch(setSelectedMintCollection(getFromNetworkStorage('selectedMintCollection', currentNetwork)))
            dispatch(setSelectedSbtCollection(getFromNetworkStorage('selectedSbtCollection', currentNetwork)))
            dispatch(setSelectedGiftCollection(getFromNetworkStorage('selectedGiftCollection', currentNetwork)))
        }
    }
)

export const sendRequestWithAuth = createAsyncThunk(
    'app/sendRequestWithAuth',
    async (request: AsyncThunkAction<any, any, any>, {dispatch, getState}): Promise<void> => {
        const state = getState() as RootState
        const {user} = state.auth

        if (!user || !user.auth) {
            dispatch(addToRequestQueue(request))
        } else {
            dispatch(request)
        }
    }
)

export const sendTransaction = createAsyncThunk(
    'app/sendTransaction',
    async (index: number, {dispatch, getState}): Promise<boolean> => {
        const state = getState() as RootState
        const {modalSendTransactions, signer} = state.app

        if (!signer || !modalSendTransactions) {
            return false
        }

        const {transactions} = modalSendTransactions
        if (index >= transactions.length) {
            return false
        }

        const showError = (title: string, text: string | string[]) => {
            dispatch(setModalError({title, text, buttons: ['close']}))
        }

        try {
            console.log(`sending transaction:`)
            console.log(transactions[index].trx)
            const tx = await signer.sendTransaction(transactions[index].trx)
            console.log(tx)
            dispatch(setModalSendTransactionStatus({index, status: true}))
            transactions[index].afterSigningCallback?.()

            try {
                const receipt = await tx.wait()
                if (receipt && receipt.status === 1) {
                    console.log(receipt)
                    dispatch(setModalSendTransactionTrxId({index, trxId: receipt.transactionHash}))
                    transactions[index].successfulSendingCallback?.(receipt)
                }
                return true
            } catch (error: any) {
                console.log(error.receipt)
                showError(i18next.t('error.transactionError'), i18next.t('error.failedTransaction'))
                return false
            }
        } catch (e: any) {
            const error: string[] = []
            error.push(e.message || e.toString())
            if (e.data && e.data.message) {
                error.push(e.data.message)
            }
            console.log(e)
            showError(i18next.t('error.signingTransactionError'), error)
            return false
        }
    }
)

export const walletChanged = createAsyncThunk(
    'app/walletChanged',
    async (_, {dispatch, getState}): Promise<void> => {
        const state = getState() as RootState
        const {currentNetwork, walletAddress} = state.app

        if (!walletAddress) {
            return
        }

        dispatch(setUser(null))
        const jwt = getFromWalletStorage('jwt')
        dispatch(setJwt(jwt))
        dispatch(requestAuth({account: walletAddress, jwt}))

        dispatch(closeAllModals())
        dispatch(setTicketLevels(null))
        dispatch(setEventsByOrganizer(null))
        dispatch(setOrganizers(null))
        dispatch(setSelectedOrganizerId(Number(getFromWalletStorage('organizerId'))))
        dispatch(setSelectedEventId(Number(getFromWalletStorage('eventId'))))
        dispatch(setTicketLevelId(null))
        dispatch(setMintCollections(null))
        dispatch(setSbtCollections(null))
        dispatch(setGiftsCollections(null))
        dispatch(setSelectedMintCollection(getFromNetworkStorage('selectedMintCollection', currentNetwork)))
        dispatch(setSelectedSbtCollection(getFromNetworkStorage('selectedSbtCollection', currentNetwork)))
        dispatch(setSelectedGiftCollection(getFromNetworkStorage('selectedSbtCollection', currentNetwork)))
        dispatch(setOnChainEvents(null))
    }
)

const revokeImage = (image: string) => {
    if (image) {
        URL.revokeObjectURL(image)
    }
}

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        addToRequestQueue: (state, action: PayloadAction<AsyncThunkAction<any, any, any>>) => {
            state.requestQueue.push(action.payload)
        },
        addToWalletQueue: (state, action: PayloadAction<IWalletQueue>) => {
            state.walletQueue.push(action.payload)
        },
        closeAllModals: (state) => {
            state.modalAddEventToShowcase = null
            state.modalAddProperties = false
            state.modalAddShowcaseToEvent = false
            state.modalAddTicketsToLazyShowcase = null
            state.modalAddTicketsToShowcase = false
            state.modalAddTicketsToShowcaseV2 = false
            state.modalConfirmation = null
            state.modalCreateDisplay = false
            state.modalCreateEvent = false
            state.modalCreateGiftsCollection = false
            state.modalCreateLazyDisplay = false
            state.modalCreateMintCollection = false
            state.modalCreateOnChainEvent = false
            state.modalCreateSbtCollection = false
            state.modalCreateShowcaseV2 = null
            state.modalCreateTicketLevels = false
            state.modalEditDisplay = null
            state.modalEditEvent = false
            state.modalEditLazyDisplay = null
            state.modalEditShowcase = null
            state.modalEditString = null
            state.modalEditTicketPrices = null
            state.modalError = null
            state.modalGenerateAIGift = false
            state.modalMintGifts = false
            state.modalMintSbt = null
            state.modalMintTickets = false
            state.modalSendToken = null
            state.modalSendTransactions = null
            state.modalVideo = null
        },
        resetImage: (state) => {
            state.externalUrl = ''
            state.imageUrl = initInputData(null)
            state.loadedImage = null
        },
        resetRequestQueue: (state) => {
            state.requestQueue = []
        },
        resetWalletQueue: (state) => {
            state.walletQueue = []
        },
        setCurrentNetwork: (state, action: PayloadAction<string | null>) => {
            if (action.payload) {
                if (!!CHAINS[action.payload]) {
                    state.web3 = new Web3(CHAINS[action.payload].rpcUrl)
                    state.currentNetwork = action.payload
                    state.modalError = null
                    return
                }
            }
            state.web3 = null
            state.currentNetwork = null
        },
        setDateFilter: (state, action: PayloadAction<Date | null>) => {
            state.dateFilter = action.payload
        },
        setDateFilterAsc: (state, action: PayloadAction<boolean>) => {
            state.dateFilterAsc = action.payload
        },
        setExternalUrl: (state, action: PayloadAction<string>) => {
            if (action.payload !== '') {
                if (state.imageUrl.value) {
                    revokeImage(state.imageUrl.value)
                }
                state.imageUrl.value = null
                state.loadedImage = null
            }
            state.externalUrl = action.payload
        },
        setImageUrlError: (state, action: PayloadAction<string | null>) => {
            if (action.payload) {
                state.imageUrl.error = {status: true, text: action.payload}
            } else {
                state.imageUrl.error = {status: false, text: ''}
            }
        },
        setLoadedImage: (state, action: PayloadAction<File | null>) => {
            state.loadedImage = action.payload
            if (!action.payload) {
                if (state.imageUrl.value) {
                    revokeImage(state.imageUrl.value)
                }
                state.imageUrl.value = null
            } else {
                state.imageUrl.value = URL.createObjectURL(action.payload)
            }
            state.externalUrl = ''
        },
        setMainPageClass: (state, action: PayloadAction<MainPageClasses | null>) => {
            state.mainPageClass = action.payload
        },
        setModalAddEventToShowcase: (state, action: PayloadAction<ModalAddEventToShowcaseType | null>) => {
            state.modalAddEventToShowcase = action.payload
        },
        setModalAddProperties: (state, action: PayloadAction<boolean>) => {
            state.modalAddProperties = action.payload
        },
        setModalAddShowcaseToEvent: (state, action: PayloadAction<boolean>) => {
            state.modalAddShowcaseToEvent = action.payload
        },
        setModalAddTicketsToLazyShowcase: (state, action: PayloadAction<ICheckedTicket | null>) => {
            state.modalAddTicketsToLazyShowcase = action.payload
        },
        setModalAddTicketsToShowcase: (state, action: PayloadAction<boolean>) => {
            state.modalAddTicketsToShowcase = action.payload
        },
        setModalAddTicketsToShowcaseV2: (state, action: PayloadAction<boolean>) => {
            state.modalAddTicketsToShowcaseV2 = action.payload
        },
        setModalConfirmation: (state, action: PayloadAction<ModalConfirmationType | null>) => {
            state.modalConfirmation = action.payload
        },
        setModalCreateDisplay: (state, action: PayloadAction<boolean>) => {
            state.modalCreateDisplay = action.payload
        },
        setModalCreateEvent: (state, action: PayloadAction<boolean>) => {
            state.modalCreateEvent = action.payload
        },
        setModalCreateGiftsCollection: (state, action: PayloadAction<boolean>) => {
            state.modalCreateGiftsCollection = action.payload
        },
        setModalCreateLazyDisplay: (state, action: PayloadAction<boolean>) => {
            state.modalCreateLazyDisplay = action.payload
        },
        setModalCreateMintCollection: (state, action: PayloadAction<boolean>) => {
            state.modalCreateMintCollection = action.payload
        },
        setModalCreateOnChainEvent: (state, action: PayloadAction<boolean>) => {
            state.modalCreateOnChainEvent = action.payload
        },
        setModalCreateSbtCollection: (state, action: PayloadAction<boolean>) => {
            state.modalCreateSbtCollection = action.payload
        },
        setModalCreateShowcaseV2: (state, action: PayloadAction<ModalCreateShowcaseV2Type | null>) => {
            state.modalCreateShowcaseV2 = action.payload
        },
        setModalCreateTicketLevels: (state, action: PayloadAction<boolean>) => {
            state.modalCreateTicketLevels = action.payload
        },
        setModalEditDisplay: (state, action: PayloadAction<string | null>) => {
            state.modalEditDisplay = action.payload
        },
        setModalEditEvent: (state, action: PayloadAction<boolean>) => {
            state.modalEditEvent = action.payload
        },
        setModalEditLazyDisplay: (state, action: PayloadAction<string | null>) => {
            state.modalEditLazyDisplay = action.payload
        },
        setModalEditShowcase: (state, action: PayloadAction<string | null>) => {
            state.modalEditShowcase = action.payload
        },
        setModalEditString: (state, action: PayloadAction<ModalEditStringType | null>) => {
            state.modalEditString = action.payload
        },
        setModalEditTicketPrices: (state, action: PayloadAction<ModalEditTicketPricesType | null>) => {
            state.modalEditTicketPrices = action.payload
        },
        setModalError: (state, action: PayloadAction<ModalErrorType | null>) => {
            state.modalError = action.payload
        },
        setModalGenerateAIGift: (state, action: PayloadAction<boolean>) => {
            state.modalGenerateAIGift = action.payload
        },
        setModalMintGifts: (state, action: PayloadAction<boolean>) => {
            state.modalMintGifts = action.payload
        },
        setModalMintSbt: (state, action: PayloadAction<ModalMintSbtType | null>) => {
            state.modalMintSbt = action.payload
        },
        setModalMintTickets: (state, action: PayloadAction<boolean>) => {
            state.modalMintTickets = action.payload
        },
        setModalSendToken: (state, action: PayloadAction<ModalSendTokenType | null>) => {
            state.modalSendToken = action.payload
        },
        setModalSendTransactions: (state, action: PayloadAction<ModalSendTransactionType | null>) => {
            state.modalSendTransactions = action.payload
        },
        setModalSendTransactionStatus: (state, action: PayloadAction<{ index: number, status: boolean }>) => {
            if (state.modalSendTransactions && action.payload.index < state.modalSendTransactions.transactions.length) {
                state.modalSendTransactions.transactions[action.payload.index].signedStatus = action.payload.status
            }
        },
        setModalSendTransactionTrxId: (state, action: PayloadAction<{ index: number, trxId: string }>) => {
            if (state.modalSendTransactions && action.payload.index < state.modalSendTransactions.transactions.length) {
                state.modalSendTransactions.transactions[action.payload.index].trxId = action.payload.trxId
            }
        },
        setModalVideo: (state, action: PayloadAction<ModalVideoType | null>) => {
            state.modalVideo = action.payload
        },
        setRedirectPath: (state, action: PayloadAction<string | null>) => {
            state.redirectPath = action.payload
        },
        setSelectedMintCollection: (state, action: PayloadAction<string | null>) => {
            state.selectedMintCollection = action.payload
            setToNetworkStorage('selectedMintCollection', action.payload, state.currentNetwork)
        },
        setSelectedSbtCollection: (state, action: PayloadAction<string | null>) => {
            state.selectedSbtCollection = action.payload
            setToNetworkStorage('selectedSbtCollection', action.payload, state.currentNetwork)
        },
        setSigner: (state, action: PayloadAction<Signer | null>) => {
            state.signer = action.payload
        },
        setWalletAddress: (state, action: PayloadAction<string | null>) => {
            state.walletAddress = action.payload
            setToStorage('walletAddress', action.payload)
        },
        setWalletQueue: (state, action: PayloadAction<IWalletQueue[]>) => {
            state.walletQueue = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(initialize.fulfilled, (state, action: PayloadAction<boolean>) => {
            if (action.payload) {
                state.initialized = action.payload
            } else {
                state.initializeError = i18next.t('error.initializeError')
            }
        })
        builder.addCase(loadExternalUrl.fulfilled, (state, action: PayloadAction<LoadingExternalUrlType | null>) => {
            if (!action.payload) {
                state.loadedImage = null
                state.imageUrl.value = null
                state.externalUrl = ''
            } else if (action.payload.error) {
                state.loadedImage = null
                state.imageUrl.value = null
            } else if (action.payload.file) {
                state.loadedImage = action.payload.file
                state.imageUrl.value = state.externalUrl || null
            } else {
                state.loadedImage = null
                state.imageUrl.value = null
                state.externalUrl = ''
            }
        })
        builder.addCase(sendTransaction.fulfilled, (state, action: PayloadAction<boolean>) => {
            if (!action.payload) {
                state.modalSendTransactions = null
            }
        })
    },
})

export const getCurrentNetwork = (state: RootState): string | null => state.app.currentNetwork
export const getDateFilter = (state: RootState): Date | null => state.app.dateFilter
export const getDateFilterAsc = (state: RootState): boolean => state.app.dateFilterAsc
export const getExternalUrl = (state: RootState) => state.app.externalUrl
export const getImageUrl = (state: RootState) => state.app.imageUrl
export const getInitialized = (state: RootState): boolean => state.app.initialized
export const getInitializeError = (state: RootState): string => state.app.initializeError
export const getLoadedImage = (state: RootState) => state.app.loadedImage
export const getMainPageClass = (state: RootState) => state.app.mainPageClass
export const getModalAddEventToShowcase = (state: RootState): ModalAddEventToShowcaseType | null => state.app.modalAddEventToShowcase
export const getModalAddProperties = (state: RootState): boolean => state.app.modalAddProperties
export const getModalAddShowcaseToEvent = (state: RootState): boolean => state.app.modalAddShowcaseToEvent
export const getModalAddTicketsToLazyShowcase = (state: RootState): ICheckedTicket | null => state.app.modalAddTicketsToLazyShowcase
export const getModalAddTicketsToShowcase = (state: RootState): boolean => state.app.modalAddTicketsToShowcase
export const getModalAddTicketsToShowcaseV2 = (state: RootState): boolean => state.app.modalAddTicketsToShowcaseV2
export const getModalConfirmation = (state: RootState): ModalConfirmationType | null => state.app.modalConfirmation
export const getModalCreateDisplay = (state: RootState): boolean => state.app.modalCreateDisplay
export const getModalCreateEvent = (state: RootState): boolean => state.app.modalCreateEvent
export const getModalCreateGiftsCollection = (state: RootState): boolean => state.app.modalCreateGiftsCollection
export const getModalCreateLazyDisplay = (state: RootState): boolean => state.app.modalCreateLazyDisplay
export const getModalCreateMintCollection = (state: RootState): boolean => state.app.modalCreateMintCollection
export const getModalCreateOnChainEvent = (state: RootState): boolean => state.app.modalCreateOnChainEvent
export const getModalCreateSbtCollection = (state: RootState): boolean => state.app.modalCreateSbtCollection
export const getModalCreateShowcaseV2 = (state: RootState): ModalCreateShowcaseV2Type | null => state.app.modalCreateShowcaseV2
export const getModalCreateTicketLevels = (state: RootState): boolean => state.app.modalCreateTicketLevels
export const getModalEditDisplay = (state: RootState): string | null => state.app.modalEditDisplay
export const getModalEditEvent = (state: RootState): boolean => state.app.modalEditEvent
export const getModalEditLazyDisplay = (state: RootState): string | null => state.app.modalEditLazyDisplay
export const getModalEditShowcase = (state: RootState): string | null => state.app.modalEditShowcase
export const getModalEditString = (state: RootState): ModalEditStringType | null => state.app.modalEditString
export const getModalEditTicketPrices = (state: RootState): ModalEditTicketPricesType | null => state.app.modalEditTicketPrices
export const getModalError = (state: RootState): ModalErrorType | null => state.app.modalError
export const getModalGenerateAIGift = (state: RootState): boolean => state.app.modalGenerateAIGift
export const getModalMintGifts = (state: RootState): boolean => state.app.modalMintGifts
export const getModalMintSbt = (state: RootState): ModalMintSbtType | null => state.app.modalMintSbt
export const getModalMintTickets = (state: RootState): boolean => state.app.modalMintTickets
export const getModalSendToken = (state: RootState): ModalSendTokenType | null => state.app.modalSendToken
export const getModalSendTransactions = (state: RootState): ModalSendTransactionType | null => state.app.modalSendTransactions
export const getModalVideo = (state: RootState): ModalVideoType | null => state.app.modalVideo
export const getRedirectPath = (state: RootState): string | null => state.app.redirectPath
export const getRequestQueue = (state: RootState): AsyncThunkAction<any, any, any>[] => state.app.requestQueue
export const getSelectedMintCollection = (state: RootState) => state.app.selectedMintCollection
export const getSelectedSbtCollection = (state: RootState) => state.app.selectedSbtCollection
export const getSigner = (state: RootState): Signer | null => state.app.signer
export const getTokenSmartcontracts = (state: RootState): string[] => {
    let result: string[] = []
    if (state.app.currentNetwork) {
        result.push(CHAINS[state.app.currentNetwork].nftMinterContract721)
        for (let item of state.mint.collections || []) {
            result.push(item.contractAddress)
        }
    }
    return result
}
export const getWalletAddress = (state: RootState): string | null => state.app.walletAddress
export const getWalletQueue = (state: RootState): IWalletQueue[] => state.app.walletQueue
export const getWeb3 = (state: RootState): Web3 | null => state.app.web3

export const {
    addToRequestQueue,
    addToWalletQueue,
    closeAllModals,
    resetImage,
    resetRequestQueue,
    resetWalletQueue,
    setCurrentNetwork,
    setDateFilter,
    setDateFilterAsc,
    setExternalUrl,
    setImageUrlError,
    setLoadedImage,
    setMainPageClass,
    setModalAddEventToShowcase,
    setModalAddProperties,
    setModalAddShowcaseToEvent,
    setModalAddTicketsToLazyShowcase,
    setModalAddTicketsToShowcase,
    setModalAddTicketsToShowcaseV2,
    setModalConfirmation,
    setModalCreateDisplay,
    setModalCreateEvent,
    setModalCreateGiftsCollection,
    setModalCreateLazyDisplay,
    setModalCreateMintCollection,
    setModalCreateOnChainEvent,
    setModalCreateSbtCollection,
    setModalCreateShowcaseV2,
    setModalCreateTicketLevels,
    setModalEditDisplay,
    setModalEditEvent,
    setModalEditLazyDisplay,
    setModalEditShowcase,
    setModalEditString,
    setModalEditTicketPrices,
    setModalError,
    setModalGenerateAIGift,
    setModalMintGifts,
    setModalMintSbt,
    setModalMintTickets,
    setModalSendToken,
    setModalSendTransactions,
    setModalSendTransactionStatus,
    setModalSendTransactionTrxId,
    setModalVideo,
    setRedirectPath,
    setSelectedMintCollection,
    setSelectedSbtCollection,
    setSigner,
    setWalletAddress,
    setWalletQueue,
} = appSlice.actions

export default appSlice.reducer
