import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {ethers} from 'ethers'
import {RootState} from './store'
import {initInputData, InputData, IProperty, ITicketPrice} from './types'

interface InputState {
    batch: InputData<number | null>
    collaterals: InputData<ITicketPrice[]>
    description: InputData<string>
    properties: InputData<IProperty[]>
    recipients: InputData<string[]>
    title: InputData<string>
    unlockTime: InputData<number | null>
}

const initialState: InputState = {
    batch: initInputData(1),
    collaterals: initInputData([]),
    description: initInputData(''),
    properties: initInputData([]),
    recipients: initInputData([]),
    title: initInputData(''),
    unlockTime: initInputData(null)
}


export const inputSlice = createSlice({
    name: 'input',
    initialState,
    reducers: {
        addCollateral: (state, action: PayloadAction<ITicketPrice>) => {
            let newState: ITicketPrice[] = [...state.collaterals.value]
            let found = false
            for (let key in newState) {
                const item = newState[key]
                if (item.token === action.payload.token && item.customContract === action.payload.customContract) {
                    const newAmount = ethers.utils.parseUnits(item.price, item.decimals).add(ethers.utils.parseUnits(action.payload.price, item.decimals))
                    newState[key].price = ethers.utils.formatUnits(newAmount, item.decimals)
                    found = true
                    break
                }
            }
            if (!found) {
                newState.push(action.payload)
            }
            state.collaterals.value = newState
        },
        addRecipient: (state, action: PayloadAction<string>) => {
            let newState: string[] = [...state.recipients.value]
            newState.push(action.payload)
            state.recipients.value = newState
        },
        delCollateral: (state, action: PayloadAction<number>) => {
            let newState: ITicketPrice[] = []
            for (let i = 0; i < state.collaterals.value.length; i++) {
                if (i !== action.payload) {
                    newState.push(state.collaterals.value[i])
                }
            }
            state.collaterals.value = newState
        },
        delRecipient: (state, action: PayloadAction<number>) => {
            let newState: string[] = []
            for (let i = 0; i < state.recipients.value.length; i++) {
                if (i !== action.payload) {
                    newState.push(state.recipients.value[i])
                }
            }
            state.recipients.value = newState
        },
        editCollateral: (state, action: PayloadAction<{ index: number, value: ITicketPrice }>) => {
            let newState: ITicketPrice[] = [...state.collaterals.value]
            newState[action.payload.index] = {...action.payload.value}
            state.collaterals.value = newState
        },
        resetState: (state) => {
            let key: keyof InputState
            for (key in initialState) {
                Reflect.set(state, key, initialState[key])
            }
        },
        setBatch: (state, action: PayloadAction<number | null>) => {
            state.batch.value = action.payload
        },
        setBatchError: (state, action: PayloadAction<string | null>) => {
            if (action.payload) {
                state.batch.error = {status: true, text: action.payload}
            } else {
                state.batch.error = {status: false, text: ''}
            }
        },
        setCollaterals: (state, action: PayloadAction<ITicketPrice[]>) => {
            state.collaterals.value = action.payload
        },
        setCollateralsError: (state, action: PayloadAction<string | null>) => {
            if (action.payload) {
                state.collaterals.error = {status: true, text: action.payload}
            } else {
                state.collaterals.error = {status: false, text: ''}
            }
        },
        setDescription: (state, action: PayloadAction<string>) => {
            state.description.value = action.payload
        },
        setDescriptionError: (state, action: PayloadAction<string | null>) => {
            if (action.payload) {
                state.description.error = {status: true, text: action.payload}
            } else {
                state.description.error = {status: false, text: ''}
            }
        },
        setProperties: (state, action: PayloadAction<IProperty[]>) => {
            state.properties.value = action.payload
        },
        setPropertiesError: (state, action: PayloadAction<string | null>) => {
            if (action.payload) {
                state.properties.error = {status: true, text: action.payload}
            } else {
                state.properties.error = {status: false, text: ''}
            }
        },
        setRecipients: (state, action: PayloadAction<string[]>) => {
            state.recipients.value = action.payload
        },
        setRecipientsError: (state, action: PayloadAction<string | null>) => {
            if (action.payload) {
                state.recipients.error = {status: true, text: action.payload}
            } else {
                state.recipients.error = {status: false, text: ''}
            }
        },
        setTitle: (state, action: PayloadAction<string>) => {
            state.title.value = action.payload
        },
        setTitleError: (state, action: PayloadAction<string | null>) => {
            if (action.payload) {
                state.title.error = {status: true, text: action.payload}
            } else {
                state.title.error = {status: false, text: ''}
            }
        },
        setUnlockTime: (state, action: PayloadAction<number | null>) => {
            state.unlockTime.value = action.payload
        },
        setUnlockTimeError: (state, action: PayloadAction<string | null>) => {
            if (action.payload) {
                state.unlockTime.error = {status: true, text: action.payload}
            } else {
                state.unlockTime.error = {status: false, text: ''}
            }
        },
    },
})

export const getBatch = (state: RootState): InputData<number | null> => state.input.batch
export const getCollaterals = (state: RootState): InputData<ITicketPrice[]> => state.input.collaterals
export const getDescription = (state: RootState): InputData<string> => state.input.description
export const getProperties = (state: RootState): InputData<IProperty[]> => state.input.properties
export const getRecipients = (state: RootState): InputData<string[]> => state.input.recipients
export const getTitle = (state: RootState): InputData<string> => state.input.title
export const getUnlockTime = (state: RootState): InputData<number | null> => state.input.unlockTime

export const {
    addCollateral,
    addRecipient,
    delCollateral,
    delRecipient,
    editCollateral,
    resetState,
    setBatch,
    setBatchError,
    setCollaterals,
    setCollateralsError,
    setDescription,
    setDescriptionError,
    setProperties,
    setPropertiesError,
    setRecipients,
    setRecipientsError,
    setTitle,
    setTitleError,
    setUnlockTime,
    setUnlockTimeError,
} = inputSlice.actions

export default inputSlice.reducer
